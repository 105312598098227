import { getDefaultOptions } from 'API/auth'
import { getLecture } from 'API/getLecture'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import React, { useEffect, useState } from 'react'
import { CustomModal } from '../../../../../components/common/useModal'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import { Switch } from '@material-ui/core'
import { getCBME } from '../../../../../API/getCBME'
console.log(getUrl('123'), getCBME(), 'process')

const resourceDates = [
	{ text: 'Visible From:', name: 'visible_from' },
	{ text: 'Start Time:', name: 'available_from' },
]

const CBME_DATA = {
	type: 'select',
	selection: [
		{
			label: 'General',
			value: 'General',
		},

		{
			value: 'ECE',
			label: 'ECE',
		},
		{
			value: 'Integration',
			label: 'Integration',
		},
		{
			value: 'AETCOM',
			label: 'AETCOM',
		},
		{
			value: 'Skills Practical/Clinic',
			label: 'Skills Practical/Clinic',
		},
	],
	default_text: 'Select Lecture Type',
	label: 'Lecture Type',
	name: 'lecture_type',
}
const Lecture: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)

	const { type_of_course, stable } = useGetCourseDetails(course)
	const { modules } = useGetModulesHook({ course, stable, type_of_course })

	const [createLink, setCreateLink] = useState(false)

	const [isCBME] = useState(process.env.GATSBY_IS_CBME === 'true' || false)

	useEffect(() => {
		console.log(process.env.GATSBY_IS_CBME, 'process')
	}, [process.env.GATSBY_IS_CBME])

	const { edit, elem_id } = useEditableHook({ location })
	const [editable, setEditable] = React.useState<any>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getLecture(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable(asset)
				}
			}
			run()
		}
	}, [edit, elem_id])
	return (
		<>
			{!createLink ? (
				<CustomModal
					header='Add Lecture'
					onClick={() => {
						if (ref.current) {
							ref.current.click()
						}
					}}
				>
					Selected: {!createLink ? 'External' : 'Webex'}
					<div className='py-2'>
						<button onClick={() => setCreateLink(true)} className='btn btn-primary'>
							Use Webex
						</button>
					</div>
					<div className='py-2'>
						<button onClick={() => setCreateLink(false)} className='btn btn-primary'>
							Use External
						</button>
					</div>
					External
					<Switch
						onChange={(e: any) => {
							setCreateLink(true)
						}}
						checked={createLink || false}
						color='default'
						inputProps={{ 'aria-label': 'checkbox with default color' }}
					/>
					Webex
					<Form
						editableState={editable}
						getRef={(_ref) => {
							if (_ref) ref = _ref
						}}
						action={async ({ state }) => {
							const linkToCall = getUrl('asset/lecture')
							const payload = {
								...state,
								course,
								duration: state.duration,
							}
							let lecture:
								| {
										uid: string
								  }
								| undefined
							if (edit === 'true') {
								lecture = await handleApi<{ uid: string }>(axios.patch(linkToCall, payload, getDefaultOptions()))
							} else {
								lecture = await handleApi<{ uid: string }>(axios.post(linkToCall, payload, getDefaultOptions()))
							}
							if (lecture) navigate(`/course/${course}/activities/lecture/${lecture.uid}`)
						}}
						text={'Add Lecture'}
						fields={[
							{
								type: 'text',
								label: 'Name',
								name: 'name',
							},
							isCBME
								? CBME_DATA
								: {
										type: 'select',
										selection: [],
										label: '',
										name: '',
								  },
							{
								type: 'textarea',
								label: 'Description',
								name: 'description',
							},
							{ label: 'Duration (in minutes)', name: 'duration', type: 'number', icon: 'bx:bx-time', labelled: true },
							{ label: 'Link', name: 'link', type: 'url', icon: 'bx:bx-link' },
							...resourceDates.map((elem) => ({
								type: 'datetime-local',
								label: elem.text,
								name: elem.name,
							})),
							modules.length > 0
								? {
										type: 'select',
										selection: modules,
										name: 'module',
										label: 'Module',
										default_text: 'Module',
								  }
								: {
										type: 'select',
										selection: [],
										label: '',
										name: '',
								  },
						]}
					></Form>
				</CustomModal>
			) : (
				<CustomModal
					header='Add Webex Lecture Link'
					onClick={() => {
						if (ref.current) {
							ref.current.click()
						}
					}}
				>
					Selected: {!createLink ? 'External' : 'Webex'}
					<div className='py-2'>
						<button onClick={() => setCreateLink(true)} className='btn btn-primary'>
							Use Webex
						</button>
					</div>
					<div className='py-2'>
						<button onClick={() => setCreateLink(false)} className='btn btn-primary'>
							Use External
						</button>
					</div>
					External
					<Switch
						onChange={(e: any) => {
							setCreateLink(false)
						}}
						checked={createLink}
						color='default'
						inputProps={{ 'aria-label': 'checkbox with default color' }}
					/>
					Webex
					<Form
						editableState={editable}
						getRef={(_ref) => {
							if (_ref) ref = _ref
						}}
						action={async ({ state }) => {
							const linkToCall = getUrl('asset/lecture')
							const payload = {
								...state,
								start: state.available_from,
								end: state.available_from + state.duration * 60 * 1000,
								course,
							}
							console.log({ payload })
							const createdMeeting = await handleApi<{ webLink: string; id: string; hostEmail: string }>(
								axios.post(
									getUrl('asset/webex/create_meeting'),
									{
										...payload,
										title: state?.['name'],
									},
									getDefaultOptions()
								)
							)
							if (createdMeeting) {
								payload.link = createdMeeting.webLink
								payload.meetingId = createdMeeting.id
								payload.hostEmail = createdMeeting.hostEmail
								let lecture:
									| {
											uid: string
									  }
									| undefined
								if (edit === 'true') {
									lecture = await handleApi<{ uid: string }>(axios.patch(linkToCall, payload, getDefaultOptions()))
								} else {
									lecture = await handleApi<{ uid: string }>(axios.post(linkToCall, payload, getDefaultOptions()))
								}
								if (lecture) navigate(`/course/${course}/activities/lecture/${lecture.uid}`)
							}
						}}
						text={'Add Lecture'}
						fields={[
							{
								type: 'text',
								label: 'Name',
								name: 'name',
							},
							{
								type: 'textarea',
								label: 'Description',
								name: 'description',
							},
							isCBME
								? CBME_DATA
								: {
										type: 'select',
										selection: [],
										label: '',
										name: '',
								  },
							{ label: 'Duration (in minutes)', name: 'duration', type: 'number', icon: 'bx:bx-time', labelled: true },
							{ label: 'Host Email', name: 'email', type: 'email' },
							...resourceDates.map((elem) => ({
								type: 'datetime-local',
								label: elem.text,
								name: elem.name,
							})),
							modules.length > 0
								? {
										type: 'select',
										selection: modules,
										name: 'module',
										label: 'Module',
								  }
								: {
										type: 'select',
										selection: [],
										label: '',
										name: '',
								  },
						]}
					></Form>
				</CustomModal>
			)}
		</>
	)
}

export default Lecture
