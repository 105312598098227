import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { UserType } from 'types/UserAdditionMetaData'
import { LinkType } from '../pages/course/[id]/activities/lecture/[lecture_id]'

export async function getLecture(lecture_id: string, viewAs: string) {
	return await handleApi<LinkType>(
		axios.get(getUrl(`asset/lecture?lecture_id=${lecture_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions())
	)
}

export async function getSubmissionLectures(lecture_id: string, course_id: string) {
	return await handleApi<(UserType & { isOpened: boolean; last_open_date: Date | null })[]>(
		axios.get(getUrl(`asset/get_lecture_submission_list?lecture_id=${lecture_id}&course_id=${course_id}`), getDefaultOptions())
	)
}
